import React, { useState, useEffect, useRef } from 'react';
import { useStory } from '../context/StoryContext';
import { useNavigate } from 'react-router-dom';
import API from '../utils/api_axios';
import { profileSchema } from './ProfileSchema';

import './AIProfileCreator.css';

function AIProfileCreator() {
  const { userId } = useStory();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [profileData, setProfileData] = useState(profileSchema.profile_data);
  const chatEndRef = useRef(null);

  useEffect(() => {
    startProfileConversation();
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const startProfileConversation = async () => {
    const initialMessage = "Hi there! I'm here to help you create a profile for your child. Let's start with your child's name. What's your child's first name?";
    setMessages([{ text: initialMessage, sender: 'ai' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    setMessages([...messages, { text: input, sender: 'user' }]);
    setInput('');

    // Log the messages going to the API call
    console.log('Messages being sent to API:', [
      ...messages,
      { text: input, sender: 'user' }
    ]);

    try {
      const response = await API.post('/api/profile/chat', { 
        messages: [...messages, { text: input, sender: 'user' }],
        profileData: profileData 
      });

      if (response.data.updatedProfile) {
        setProfileData(response.data.updatedProfile);
      }

      if (response.data.profileComplete) {
        saveProfile();
        setMessages(prevMessages => [...prevMessages, { text: "Great! Your profile has been saved successfully.", sender: 'ai' }]);
      } else {
        const aiMessage = response.data.nextQuestion || response.data.message;
        setMessages(prevMessages => [...prevMessages, { text: aiMessage, sender: 'ai' }]);
      }
    } catch (error) {
      console.error('Error in chat:', error);
    }
  };

  const saveProfile = async () => {
    try {
      const response = await API.post('/api/files/create-profile', {
        userId,
        profileInfo: {
          profile_id: null,
          version: 1,
          profile_data: profileData
        }
      });
      console.log("Profile saved:", response.data);
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  const goToProfileInformation = () => {
    navigate('/profile-information', { state: { profileData } });
  };

  return (
    <div className="ai-profile-creator">
      <div className="chat-container">
        <button onClick={goToProfileInformation} className="view-profile-info">
            Create with form
        </button>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            <div className="message-bubble">{message.text}</div>
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="chat-input-form">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your response here..."
          className="chat-input"
        />
        <button type="submit" className="chat-send-button">Send</button>
      </form>
    </div>
  );
}

export default AIProfileCreator;