import React, { useState } from 'react';

import { GetGenreFontFamily, GetGenreFontWeight, GetGenreCharCount } from '../../modules/StoryGenres';

import { useStory } from '../../context/StoryContext';
import { useShelf } from '../../context/BookShelfContext';

import API from '../../utils/api_axios';

const generator = 'Flux';
// const generator = 'SDXL';
// const generator = 'DallE3';


const def_storyPrompt = "Un-oh, it looks like we are missing the story idea.";

// const __DEV__ = true;

// const BookUtils = ( ) => {
    // Context imports
    // const { 
    //     userId,
    //     imageGenerator,
    //     activeProfileId,
    //     setStoryTitleImageUrl,
    //     setStoryTitleImageKey,
    //     storyGenre,
    //     titleImageGenerated,
    //     setTitleImageGenerated
    // } = useStory();

    // const {
    //     books,
    //     setBooks,
    //     setBooksVisible,
    //     setStartBookIndex,
    //     numVisible
    // } = useShelf();
        
    // Local state
    // const [hasBeenCalled, setHasBeenCalled] = useState(false);
    // // const [titleImageGenerated, setTitleImageGenerated] = useState(false);
    // const [genError, setGenError] = useState(null); // Data not yet loaded

    //now that we have summaryData, we can use it to get the title image
    // export const GetTitleImage = (inputSummaryData, outputImageGenerator) => {
    //     const { 
    //         userId,
    //         imageGenerator,
    //         activeProfileId,
    //         setStoryTitleImageUrl,
    //         setStoryTitleImageKey,
    //         titleImageGenerated,
    //         setTitleImageGenerated
    //     } = useStory();

    export const GetTitleImage = (
        userId,
        imageGenerator,
        activeProfileId,
        setStoryTitleImageUrl,
        setStoryTitleImageKey,
        titleImageGenerated,
        setTitleImageGenerated,
        inputSummaryData
        // outputImageGenerator
    ) => {
        // const getTitleImage = (inputSummaryData, outputImageGenerator) => {
        if (!titleImageGenerated && inputSummaryData !== null) {
            console.log("inputSummaryData:", inputSummaryData);
            console.log("imageGenerator:", imageGenerator);

            API.post('/api/story/title-picture', { 
                    userId: userId,
                    profileId: activeProfileId, 
                    summaryData: inputSummaryData, 
                    // generator: outputImageGenerator 
                    generator: imageGenerator 
                }).then((response) => {
                    console.log(response);
                    if (imageGenerator === 'DallE3') {
                        if (response.data.storyImage) {
                            // const imageUrl = response.data.storyImage.data[0].url;
                            // const imageUrl = response.data.storyImage[0];
                            const imageUrl = response.data.storyImage;
                            const imageKey = response.data.fileKey;
                            console.log(imageUrl);
                            console.log(imageKey);
                            setStoryTitleImageUrl(imageUrl);
                            setStoryTitleImageKey(imageKey);

                            // return imageUrl;
                        }
                    } else {
                        if (response.data.storyImage) {
                            // const imageUrl = response.data.storyImage[0];
                            const imageUrl = response.data.storyImage;
                            const imageKey = response.data.fileKey;
                            console.log(imageUrl);
                            console.log(imageKey);
                            setStoryTitleImageUrl(imageUrl);
                            setStoryTitleImageKey(imageKey);

                            // return imageUrl;
                        }
                    }
                    setTitleImageGenerated(true);
                })
                .catch((error) => {
                    console.error('Error fetching title image:', error);
                    const msgContainer = document.getElementById('msg-container');
                    msgContainer.style.visibility = "visible";
                    // const msgTxt = document.getElementById('msg-txt');
                    // msgTxt.style.innerText = "The cover image could not be generated. Please try again.";
                    // setStoryTitleImageUrl(imageUrl);
                    
                });
                
            // setTitleImageGenerated(true);
        }
    };


    // export const CreateTitleSVG = (bookTitle, index) => {
    //     if (!bookTitle) return null;
    //     if (!booksVisible[index]) return null;

    //     const storyGenre = booksVisible[index].data.genre;

    export const CreateTitleSVG = (bookTitle, storyGenre) => {
        // const {
        //     storyGenre
        // } = useStory();
        // const storyGenre = "Test";

        if (!bookTitle) return null;

        const genreFontFamily = GetGenreFontFamily(storyGenre);
        const genreFontWeight = GetGenreFontWeight(storyGenre);
        const genreFontColor = "white";
        const genreCharCount = GetGenreCharCount(storyGenre);

        var classGenre = null;
        if (storyGenre) {
            classGenre = storyGenre.replace(' ', '');
        }

        console.log("genreFontFamily:", genreFontFamily);
        console.log("genreFontWeight:", genreFontWeight);

        const maxCharsPerLine = 17;
        const words = bookTitle.split(' ');
        const lines = [];
        let currentLine = [];

        // Split the title into lines based on the maximum number of characters allowed per line
        words.forEach(word => {
            if ((currentLine + word).length <= maxCharsPerLine) {
                currentLine += (currentLine.length > 0 ? ' ' : '') + word;
            } else {
                lines.push(currentLine);
                currentLine = word; // Start a new line with the current word
            }
        });

        // Add the last line if there are any remaining words
        if (currentLine.length > 0) {
            lines.push(currentLine);
        }

        // Calculate the height of the SVG based on the number of lines
        const fontSize = 40; // Adjust the font size as necessary
        const lineHeight = fontSize * 1.1; // Adjust line height as necessary
        const svgHeight = lines.length * lineHeight + 20; // Add some padding
        const svgWidth = 400; // Add some padding        

        // Construct the SVG content
        let titleSvg = `<svg id="book-title-svg-obj" width="${svgWidth}" height="${svgHeight}" viewBox="0 0 ${svgWidth} ${svgHeight}">
            <defs>
                <filter id="blur" x="-1" y="-1" width="200%" height="200%">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="0"/>
                    <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 0.75 0"/>
                </filter>
                <filter id="solid" x="-1" y="-1" width="200%" height="200%">
                    <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 0.7 0"/>
                </filter>
            </defs>`;

        // Add each line of text to the SVG
        lines.forEach((line, i) => {
            const y = (i + 1) * lineHeight;

            // <text class="${classGenre}" x="50%" y="${y}" text-anchor="middle" font-family="${genreFontFamily}" font-weight="${genreFontWeight}" font-size="${fontSize}" filter="url(#blur)" fill="${genreFontColor}">${line}</text>            

            titleSvg += `
                <text class="${classGenre}" x="50%" y="${y}" text-anchor="middle" font-size="${fontSize}" filter="url(#blur)" fill="${genreFontColor}">${line}</text>            
            `;
            // <text x="50%" y="${y}" text-anchor="middle" font-family="${genreFontFamily}" font-weight="${genreFontWeight}" font-size="${fontSize}" filter="url(#solid)" fill="none" stroke="black" stroke-width="1">${line}</text>


        });

        // Close the SVG string
        titleSvg += `</svg>`;

        // changeFillColor();

        return titleSvg;

        // const bookTitleSvg = document.getElementById(`book-title-svg-${index}`);
        // if (bookTitleSvg) bookTitleSvg.innerHTML = titleSvg;
    }

    // export const DeleteBook = (event, bookData, index) => {
    //     const { 
    //         userId
    //     } = useStory();
    
    //     const {
    //         books,
    //         setBooks,
    //         setBooksVisible,
    //         setStartBookIndex,
    //         numVisible
    //     } = useShelf();

    //     event.stopPropagation();
    //     console.log('Deleting book:', bookData.data.title);
    //     console.log('bookId:', bookData.book_id);
    //     console.log('book: ', bookData);
    //     console.log('bookIndex: ', index);

    //     const bookId = bookData.book_id;
    //     const bookTitle = bookData.data.title;

    //     //create a popup asking the user to confirm the deletion
    //     if (!window.confirm(`Are you sure you want to delete ${bookTitle}?`)) {
    //         return;
    //     }

    //     API.post('/api/story/delete-book', {
    //         userId: userId,
    //         bookId: bookId,
    //         bookData: bookData
    //     }).then((response) => {
    //         console.log(response);
    //         if (response.data.success) {
    //             console.log('Book deleted:', bookTitle);
    //             // // Remove the book from the bookshelf
    //             const newBooks = books.filter(book => book.book_id !== bookId);
    //             setBooks(newBooks);
    //             // remove the book from the booksVisible
    //             // const newVisibleBooks = booksVisible.filter(book => book.book_id !== bookId);
    //             const startIndex = 0;
    //             const endIndex = Math.min(newBooks.length, numVisible);
    //             const newVisibleBooks = newBooks.slice(startIndex, endIndex);

    //             // setBooks(newBooks);
    //             setBooksVisible(newVisibleBooks);
    //             // setIndex(index - 1);
    //             setStartBookIndex(index - 1);
    //         }
    //     })
    //     .catch((error) => {
    //         console.error('Error deleting book:', error);
    //     });
        
    //     //alert window that the book has been deleted
    //     alert(`${bookTitle} has been deleted.`);
    // }

    export const GetNewSummary = () => {
        alert(`You want to regen the summary`);
    };

    // export const HandleBookClick = (bookId) => {
    //     const { 
    //         userId
    //     } = useStory();
    //     // Redirect the user to the book view page
    //     window.location.href = `/story-book/${userId}/${bookId}`;
    // };
// };

// export default BookUtils;

// module.exports = {
//     GetTitleImage,
//     CreateTitleSVG,
//     DeleteBook,
//     GetNewSummary,
//     handleBookClick
// };
