import React, { useEffect } from 'react';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import Cookies from 'js-cookie';

import { useStory } from '../context/StoryContext';

import profile_placeholder from '../imgs/generic_profile.png';

import API from '../utils/api_axios';

import './login.css'; 

function LoginPage() {
    const { setUserId, 
            credentialId, 
            setCredentialId, 
            userProfile, 
            setUserProfile, 
            setProfiles, 
            loggedIn, 
            setLoggedIn, 
            setActiveProfileId} = useStory();

    // ============================================ Page useEffect ============================================
    useEffect(() => {
        if (userProfile) {
            console.log('loginPage loggedIn:', loggedIn);
            console.log('loginPage userProfile:', userProfile);
        }

        const profilesContainer = document.getElementById('profiles-container');
        if (profilesContainer) {
            profilesContainer.style.display = 'none';
        }

        const demoProfilesContainer = document.getElementById('demo-profiles-container');
        if (demoProfilesContainer) {
            demoProfilesContainer.style.display = 'none';
        }

        // Get user info if they are already logged in
        const userToken = localStorage.getItem('userToken');
        setLoggedIn(localStorage.getItem('loggedIn'));

        if (loggedIn && userToken) {
            API.post(`/api/user/get-user`, {
                userToken: userToken
            })
                .then((response) => {
                    const profileData = response.data.profileData;
                    console.log("profileData.name: " + profileData.name);
                    console.log("profileData.picture: " + profileData.picture);
                    console.log("profileData.email: " + profileData.email);
                    console.log("profileData.user_id: " + profileData.user_id);
                    setUserProfile(profileData);
                    setLoggedIn(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);

    // const GetLoggedInDetails = () => {
    //     const userToken = localStorage.getItem('userToken');
    //     const loggedIn = localStorage.getItem('loggedIn');

    //     if (loggedIn && userToken) {
    //         API.get(`/api/auth/${userToken}`)
    //             .then((response) => {
    //                 const profileData = response.data.profileData;
    //                 setUserProfile(profileData);
    //                 setLoggedIn(true);
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     }
    // };

    const onGoogleLoginSuccess = (loginResponse) => {
        console.log('Login Success: Google loginResponse:', loginResponse);
        setCredentialId(loginResponse.credential);
    };

    const onGoogleLoginError = (res) => {
        console.log('Login failed: res:', res);
    };
    // ============================================ Page useEffect ============================================

    // ============================================ Login useEffect ============================================
    useEffect( () => {
        if (credentialId) {
            console.log('credentialId:', credentialId);

            API.post('/api/auth/google-login', {
                credentialId: credentialId
            })
                .then((response) => {
                    console.log(response);

                    console.log("response.cookies: " + response.cookies);

                    if (response.data) {
                        const profileData = response.data.profileData;
                        console.log("profileData: " + profileData);
                        setUserProfile(profileData);
                        setLoggedIn(true);
                        localStorage.setItem('userToken', profileData.user_id);
                        localStorage.setItem('loggedIn', true);

                        // setUserId(profileData.user_id.replaceAll('"', ''));
                        // Cookies.set('accessToken', JSON.stringify(profileData.id), { expires: 7, path: '/' });
                        // Cookies.set('userToken', JSON.stringify(profileData.user_id), { expires: 7, path: '/' });
                        
                        // localStorage.setItem('userProfile', JSON.stringify(profileData));
                        
                        // Cookies.set('userProfile', JSON.stringify(profileData), { expires: 7, path: '/' });
                        // Cookies.set('loggedIn', 'true', { expires: 7, path: '/' });
                        window.location.href = '/';
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [ credentialId ]);
    // ============================================ Login useEffect ============================================

    // ============================================ Logout function ============================================
    // const logOut = () => {
    function logOut() {
        // Call the backend to clear the access_token cookie
        API.post('/api/auth/logout')
            .then(() => {
                // Clear local state and storage
                setUserId(null);
                setCredentialId(null);
                googleLogout();
                setUserProfile(null);
                setProfiles([]);
                setLoggedIn(false);
                setActiveProfileId(null);
                
                // Clear localStorage
                localStorage.removeItem('userToken');
                localStorage.removeItem('loggedIn');

                // Optionally, clear any remaining cookies (not necessary if backend clears it)
                Cookies.remove('access_token');
                Cookies.remove('g_state');

                // Cookies.remove('userToken');
                // Cookies.remove('userProfile');
                // Cookies.remove('loggedIn');
                
                // Redirect to the login page or home
                window.location.href = '/'; // or '/'
            })
            .catch((error) => {
                console.error('Error during logout:', error);
            });

        console.log('Logout Success');
    };
    // ============================================ Logout function ============================================

    return (
        <div className='userProfile'>
            <div className='login-card'>
                {userProfile ? (
                    <div>
                        <div  className='profile-picture' >
                            <img src={userProfile.picture !== null ? userProfile.picture : profile_placeholder} alt="user image" />
                        </div>
                        <h3>User Account</h3>
                        <p>Name: {userProfile.name}</p>
                        <p>Email Address: {userProfile.email}</p>
                        <button className='neu-button' onClick={() => logOut()}>Log out</button>
                    </div>
                ) : (
                <>
                    <h1>Login</h1>
                    <p>Please login with Google as we work to support other login methods.</p>
                    <GoogleLogin
                        onSuccess={loginResponse => {
                            console.log(loginResponse);
                            onGoogleLoginSuccess(loginResponse);
                        }}
                        onError={res => {
                            console.log('Login Failed' + res);
                            onGoogleLoginError(res);
                        }}
                        // useOneTap
                    />
                </>
                )}
            </div>
        </div>
    );
}
export default LoginPage;