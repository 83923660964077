// src/SkeletonLoader.js
import React from 'react';
import './skeleton-loader.css'; // Make sure to create this CSS file
import './book-link.css'; // Make sure to create this CSS file
import { FaShareAlt } from 'react-icons/fa';

const BookLink = ({ width, height, userId, bookId, useDefaultIcon = false }) => {
  console.log("BookLink");
  console.log("userId:", userId);
  console.log("bookId:", bookId);

  function GetLink() {
    console.log("GetLink");
    console.log("userId:", userId);
    console.log("bookId:", bookId);
    const baseUrl = `${window.location.protocol}//${window.location.hostname}:3001`;
    window.open(`${baseUrl}/story-book/${userId}/${bookId}`, "_blank");
  };

  const defaultShareIcon = <FaShareAlt size={24} />;
  const sampleSvgShareIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <circle cx="18" cy="5" r="3"></circle>
      <circle cx="6" cy="12" r="3"></circle>
      <circle cx="18" cy="19" r="3"></circle>
      <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
      <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
    </svg>
  );

  return (
    <div className="book-link" onClick={() => GetLink()} style={{ width, height }}>
      {useDefaultIcon ? defaultShareIcon : sampleSvgShareIcon}
    </div>
  );
};

export default BookLink;