import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./CheckoutPage.css";
import { KT_API_URL } from '../utils/api_axios';
import { useUser } from '../context/UserContext';

const CheckoutPage = () => {
	const [status, setStatus] = useState("processing");
	const [message, setMessage] = useState("");
	const location = useLocation();
	const navigate = useNavigate();
	const { userId } = useUser();

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const sessionId = query.get("session_id");

		if (sessionId) {
			processCheckout(sessionId);
		} else if (query.get("canceled")) {
			setStatus("canceled");
			setMessage("Your order was canceled. You can try again when you're ready.");
			setTimeout(() => navigate("/pricing"), 3000);
		} else {
			setStatus("error");
			setMessage("No session ID found. Please try again.");
		}
	}, [location]);

	const processCheckout = async (sessionId) => {
		try {
			const response = await axios.post(`${KT_API_URL}/api/cart/checkout-success`, {
				session_id: sessionId,
				userId: userId
			});

			if (response.data.success) {
				setStatus("success");
				setMessage(response.data.message);
				setTimeout(() => navigate("/account"), 3000); // Redirect to account page after 3 seconds
			} else {
				setStatus("error");
				setMessage("An error occurred while processing your payment. Please try again.");
			}
		} catch (error) {
			console.error("Error processing checkout:", error);
			setStatus("error");
			setMessage("An error occurred while processing your payment. Please try again.");
		}
	};

	return (
		<div className="checkout-page">
			{status === "processing" && (
				<div className="loading-container">
					<div className="loading-spinner"></div>
					<p>Please wait while we update your account...</p>
				</div>
			)}
			{status === "success" && (
				<div className="success-container">
					<h2>Success!</h2>
					<p>{message}</p>
					<p>Redirecting to your account page...</p>
				</div>
			)}
			{status === "canceled" && (
				<div className="canceled-container">
					<h2>Order Canceled</h2>
					<p>{message}</p>
					<p>Redirecting to the pricing page...</p>
				</div>
			)}
			{status === "error" && (
				<div className="error-container">
					<h2>Error</h2>
					<p>{message}</p>
					<button onClick={() => navigate("/pricing")}>Return to Pricing</button>
				</div>
			)}
		</div>
	);
};

export default CheckoutPage;