import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSwipeable } from 'react-swipeable';

import { useStory } from '../context/StoryContext';
import { useShelf } from '../context/BookShelfContext';

import BookCover from './book/BookCover';

import { GetGenreColor, GetGenreFontFamily, GetGenreFontWeight, GetGenreCharCount } from '../modules/StoryGenres';
import ThemeIcons from '../modules/StoryThemeIcons';
import SettingIcons from '../modules/StorySettingIcons';

import bkgd_img from '../imgs/default_background.png';

import API from '../utils/api_axios';

import './bookshelf-page.css';

const navArrowLeft = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="#f1f1f1"></path> </g></svg>;
const navArrowRight = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="#f1f1f1"></path> </g></svg>;
// const trashCan = <svg viewBox="0 0 1024 1024" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M667.8 362.1H304V830c0 28.2 23 51 51.3 51h312.4c28.4 0 51.4-22.8 51.4-51V362.2h-51.3z" fill="transparent"></path><path d="M750.3 295.2c0-8.9-7.6-16.1-17-16.1H289.9c-9.4 0-17 7.2-17 16.1v50.9c0 8.9 7.6 16.1 17 16.1h443.4c9.4 0 17-7.2 17-16.1v-50.9z" fill="#CCCCCC"></path><path d="M733.3 258.3H626.6V196c0-11.5-9.3-20.8-20.8-20.8H419.1c-11.5 0-20.8 9.3-20.8 20.8v62.3H289.9c-20.8 0-37.7 16.5-37.7 36.8V346c0 18.1 13.5 33.1 31.1 36.2V830c0 39.6 32.3 71.8 72.1 71.8h312.4c39.8 0 72.1-32.2 72.1-71.8V382.2c17.7-3.1 31.1-18.1 31.1-36.2v-50.9c0.1-20.2-16.9-36.8-37.7-36.8z m-293.5-41.5h145.3v41.5H439.8v-41.5z m-146.2 83.1H729.5v41.5H293.6v-41.5z m404.8 530.2c0 16.7-13.7 30.3-30.6 30.3H355.4c-16.9 0-30.6-13.6-30.6-30.3V382.9h373.6v447.2z" fill="#ffffff"></path><path d="M511.6 798.9c11.5 0 20.8-9.3 20.8-20.8V466.8c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0 11.4 9.3 20.7 20.8 20.7zM407.8 798.9c11.5 0 20.8-9.3 20.8-20.8V466.8c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0.1 11.4 9.4 20.7 20.8 20.7zM615.4 799.6c11.5 0 20.8-9.3 20.8-20.8V467.4c0-11.5-9.3-20.8-20.8-20.8s-20.8 9.3-20.8 20.8v311.4c0 11.5 9.3 20.8 20.8 20.8z" fill="#ffffff"></path></g></svg>;

const BookShelfPage = ( { thisLandingShelf } ) => {
    // Context imports
    const {
        userId,
        loggedIn
      } = useStory();
      
    const {
        books,
        setBooks,
        booksVisible,
        setBooksVisible,
        index,
        setIndex,
        landingShelfOn,
        setLandingShelfOn,
        startBookIndex,
        setStartBookIndex,
        numVisible,
        setNumVisible,
        maxBooks,
        setBackgroundUrl
      } = useShelf();

    // Local state
    // const maxBooks = 50;
    const itemsPerPage = 1; 
    const ROTATION = 75; // degrees
    const BASE_ZINDEX = 0;
    const MAX_ZINDEX = 2*maxBooks;

    // const [startBookIndex, setStartBookIndex] = useState((thisLandingShelf || landingShelfOn) ? 0 : Math.floor(maxBooks / 2));
    const [advancing, setAdvancing] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [alreadyChanged, setAlreadyChanged] = useState(false);

    // Refs
    const bookshelfRef = useRef(null);
    const booksRef = useRef([]);
    const paginateRef = useRef(null);

    // ============================================ Page useEffect ============================================
    useEffect(() => {
        console.log('BookShelfPage loading books...')

        // if (thisLandingShelf && !loggedIn) {
        if ((thisLandingShelf || landingShelfOn) && !loggedIn) {
            const profiles = document.getElementById('profiles-container');
            if (profiles) profiles.style.display = 'none';

            // const demoProfiles = document.getElementById('demo-profiles-container');
            // if (demoProfiles) demoProfiles.style.display = 'none';
        }
        
        if (thisLandingShelf || landingShelfOn) { // Get books from the public bookshelf
            console.log('Public books length:', books.length);
            if (books.length == 0) {
                //Get books for bookshelf
                API.get('/api/public/public-bookshelf', {}).then((response) => {
                    console.log(response);
                    if (response.data.publicBooks) {
                        setNumVisible(Math.min(maxBooks, response.data.publicBooks.length));

                        if (response.data.publicBooks.length < maxBooks) {
                            setNumVisible(response.data.publicBooks.length);
                        }
                        const startIndex = Math.max(0, response.data.publicBooks.length - numVisible);
                        const endIndex = Math.min(response.data.publicBooks.length, response.data.publicBooks.length);
                        const _booksVisible = response.data.publicBooks.slice(startIndex, endIndex);
                        console.log('_booksVisible:', _booksVisible);
                        setBooksVisible(_booksVisible);

                        console.log('setBooksVisible:', booksVisible.current);
                        setTotalPages(Math.ceil(_booksVisible.length / itemsPerPage));
                        setBooks(response.data.publicBooks);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching books:', error);
                });
            } else {
                const startIndex = Math.max(0, books.length - numVisible);
                const endIndex = Math.min(books.length, books.length);
                const _booksVisible = books.slice(startIndex, endIndex);
                console.log('_booksVisible:', _booksVisible);
                setBooksVisible(_booksVisible);
                setTotalPages(Math.ceil(_booksVisible.length / itemsPerPage));
            }
        } else { // Load the user's bookshelf
            const kidsTalesLogo = document.getElementById('nav-container');
            kidsTalesLogo.classList.add('small');

            // Get books for users bookshelf
            console.log('User books length:', books.length);
            // if (books.length == 0 && userId) {
            if (userId && loggedIn) {
                //Get books for bookshelf
                API.get('/api/story/fill-bookshelf', {
                    userId
                }).then((response) => {
                    console.log(response);
                    if (response.data.books) {
                        setNumVisible(Math.min(maxBooks, response.data.books.length));

                        for (let _book of response.data.books) {
                            console.log('book:', _book);
                        }
                        setStartBookIndex(0);
                        const startIndex = 0;
                        // const startIndex = Math.max(0, response.data.books.length - numVisible);
                        const endIndex = Math.min(response.data.books.length, numVisible);
                        // const endIndex = Math.min(response.data.books.length, response.data.books.length);
                        const _booksVisible = response.data.books.slice(startIndex, endIndex);
                        console.log('_booksVisible:', _booksVisible);
                        setBooksVisible(_booksVisible);

                        console.log('setBooksVisible:', booksVisible.current);
                        setTotalPages(Math.ceil(_booksVisible.length / itemsPerPage));
                        setBooks(response.data.books);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching books:', error);
                });
            }
        }
    }, [landingShelfOn]);
    // ============================================ Page useEffect ============================================

    // ============================================ Index useEffect ============================================
    useEffect(() => {
        if (index >= booksVisible.length) {
            setIndex(0);
        }

        console.log('index change render:', index);
        render();
    }, [index]);
    // ============================================ Index useEffect ============================================


    // ============================================ LandingShelf useEffect ============================================
    useEffect(() => {
        console.log('thisLandingShelf:', thisLandingShelf);
        console.log('landingShelfOn:', landingShelfOn);
        console.log('advancing:', advancing);
        if ((thisLandingShelf || landingShelfOn) && !advancing) {
            setAdvancing(true);
            const interval = setInterval(() => {
                setIndex((prevIndex) => (prevIndex + 1));
            }, 5000);

            return () => {
                clearInterval(interval);
            };
        }
    }, []);
    // ============================================ LandingShelf useEffect ============================================

    // ============================================ Paginate useEffect ============================================
    useEffect(() => {
        const paginateLinks = document.getElementsByClassName('paginate-link');
        for (let i = 0; i < paginateLinks.length; i++) {
            console.log('paginateLinks[i]:', paginateLinks[i]);
            paginateLinks[i].innerHTML = '•';
        }
    }, [paginateRef]);
    // ============================================ Paginate useEffect ============================================

    // ============================================ Books useEffect ============================================
    useEffect(() => {
        if (booksVisible.length > 0) {
            const bookShelf = document.getElementById('bookshelf');
            bookShelf.style.visibility = 'visible';
            booksRef.current = Array.from(bookshelfRef.current.querySelectorAll('section'));
            setIndex(startBookIndex);
            console.log('booksVisible render...');
            render();
        }
    }, [booksVisible]);
    // ============================================ Books useEffect ============================================

    // ============================================ Keyed book advance useEffect ============================================
    useEffect(() => {
        document.addEventListener('keydown', keyDown, false);
        return () => {
            document.removeEventListener('keydown', keyDown, false);
        };
    }, []);
    // ============================================ Keyed book advance useEffect ============================================

    // ============================================ Page functions ============================================
    const handlers = useSwipeable({
        onSwiped: (eventData) => SwipeAction(eventData),
        // onSwiping: (eventData) => SwipingAction(eventData),
    });

    function SwipeAction(eventData) {
    // const SwipeAction = (eventData) => {
        // console.log("User swiping...", eventData);
        if (eventData.dir === 'Right') {
            prevBook();
        }
        if (eventData.dir === 'Left') {
            nextBook();
        }
    };

    function SwipingAction(eventData) {
    // const SwipingAction = (eventData) => {
        // console.log("User swiping...", eventData);
        const { deltaX } = eventData;
        const scrollDistance = 10;

        if (Math.floor(Math.abs(deltaX % scrollDistance)) === 0) {
            if (!alreadyChanged) {
                if (eventData.dir === 'Right') {
                    setAlreadyChanged(true);
                    prevBook();
                }
                if (eventData.dir === 'Left') {
                    setAlreadyChanged(true);
                    nextBook();
                }
            }
            // setIndex((prevIndex) => prevIndex - 1);
        } else if (Math.floor(Math.abs(deltaX % 10)) === 1) {
            setAlreadyChanged(false);
        }
    };

    function refPassthrough(el) {
    // const refPassthrough = (el) => {
        // call useSwipeable ref prop with el
        handlers.ref(el);

        // set myRef el so you can access it yourself
        bookshelfRef.current = el;
    }


    function SetBackgroundImage(imageUrl) {
        // const coverContainer = document.getElementById('App-container');

        // const image = new Image();
        // image.src = imageUrl;

        setBackgroundUrl(imageUrl);
        localStorage.setItem("backgroundImageUrl", imageUrl);

        // image.onload = () => {
        //     coverContainer.style.background = `url(${imageUrl}) no-repeat 50% 50% / cover`;
        // };

        // image.onerror = () => {
        //     const defaultImageUrl = bkgd_img;
        //     coverContainer.style.background = `url(${defaultImageUrl}) no-repeat 50% 50% / cover`;
        // };
    }

    function render() {
        console.log('\tRendering bookshelf...');
        console.log('booksVisible.length:', booksVisible.length);
        // Loop through book & transform positions
        booksRef.current = Array.from(bookshelfRef.current.querySelectorAll('section'));

        // alert(bookshelfRef.current.querySelectorAll('section'));
        // alert('booksRef.current.length:', booksRef.current.length);
        // alert('booksRef.current:', booksRef.current);

        

        booksRef.current.forEach((book, i) => {
            // alert('book:', book);
            if (i < index) {
                book.style.transform = `translateX(-${6 + 2 * (index - i)}em) rotateY(${ROTATION}deg)`;
                // book.style.zIndex = BASE_ZINDEX + i;
                book.style.zIndex = MAX_ZINDEX - (2*maxBooks - i);
                book.firstElementChild.classList.remove('book-cover-active');
                var x = book.children[1].children[0];
                // x.style.display = 'none';
                x.classList.remove('book-summary-active');
            } else if (i === index) {
                console.log(`i: ${i} === index: ${index}`);

                book.style.transform = `rotateY(0deg) translateZ(140px)`;
                book.style.zIndex = MAX_ZINDEX;
                book.firstElementChild.classList.add('book-cover-active');
                var x = book.children[1].children[0];
                if (!landingShelfOn) {
                    // x.style.display = 'show';
                    x.classList.add('book-summary-active');
                }
                SetBackgroundImage(booksVisible[index].data.title_image_blob || booksVisible[index].data.cf_title_image);
            } else {
                book.style.transform = `translateX(${6 + 2 * (i - index)}em) rotateY(-${ROTATION}deg)`;
                // book.style.zIndex = BASE_ZINDEX + (booksRef.current.length - i);
                book.style.zIndex = (MAX_ZINDEX - maxBooks) - (i - maxBooks);
                book.firstElementChild.classList.remove('book-cover-active');
                var x = book.children[1].children[0];
                // x.style.display = 'none';
                x.classList.remove('book-summary-active');
            }
        });
    }
    
    function prevBook() {
        if (index > 0) {
            setIndex((prevIndex) => prevIndex - 1);
        }
    }

    function nextBook() {
        if (index + 1 < booksRef.current.length) {
            setIndex((prevIndex) => prevIndex + 1);
        }
    }

    function keyDown(event) {
        switch (event.keyCode) {
            case 37:
                prevBook();
                break;
            case 39:
                nextBook();
                break;
                default:
                break;
        }
    }

    // const handleBookClick = (bookId) => {
    //     // Redirect the user to the book view page
    //     window.location.href = `/story-book/${userId}/${bookId}`;
    // };

    const handlePageChange = (selectedPage) => {
        setIndex(selectedPage.selected);
        // console.log('booksVisible:', booksVisible);
        // CreateTitleSVG(booksVisible[index].data.title);
        console.log('selectedPage:', selectedPage);
    };

    // function CreateTitleSVG(bookTitle, index) {
    //     if (!bookTitle) return null;
    //     if (!booksVisible[index]) return null;

    //     const storyGenre = booksVisible[index].data.genre;

    //     const genreFontFamily = GetGenreFontFamily(storyGenre);
    //     const genreFontWeight = GetGenreFontWeight(storyGenre);
    //     const genreFontColor = "white";
    //     const genreCharCount = GetGenreCharCount(storyGenre);

    //     var classGenre = null;
    //     if (storyGenre) {
    //         classGenre = storyGenre.replace(' ', '');
    //     }

    //     // console.log("genreFontFamily:", genreFontFamily);
    //     // console.log("genreFontWeight:", genreFontWeight);

    //     const maxCharsPerLine = 17;
    //     const words = bookTitle.split(' ');
    //     const lines = [];
    //     let currentLine = [];
    
    //     // Split the title into lines based on the maximum number of characters allowed per line
    //     words.forEach(word => {
    //         if ((currentLine + word).length <= maxCharsPerLine) {
    //             currentLine += (currentLine.length > 0 ? ' ' : '') + word;
    //         } else {
    //             lines.push(currentLine);
    //             currentLine = word; // Start a new line with the current word
    //         }
    //     });

    //     // Add the last line if there are any remaining words
    //     if (currentLine.length > 0) {
    //         lines.push(currentLine);
    //     }
    
    //     // Calculate the height of the SVG based on the number of lines
    //     const fontSize = 40; // Adjust the font size as necessary
    //     const lineHeight = fontSize * 1.1; // Adjust line height as necessary
    //     const svgHeight = lines.length * lineHeight + 20; // Add some padding
    //     const svgWidth = 400; // Add some padding        
    
    //     // Construct the SVG content
    //     let titleSvg = `<svg id="book-title-svg-obj" width="${svgWidth}" height="${svgHeight}" viewBox="0 0 ${svgWidth} ${svgHeight}">
    //         <defs>
    //             <filter id="blur" x="-1" y="-1" width="200%" height="200%">
    //                 <feGaussianBlur in="SourceGraphic" stdDeviation="0"/>
    //                 <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 0.75 0"/>
    //             </filter>
    //             <filter id="solid" x="-1" y="-1" width="200%" height="200%">
    //                 <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 0.7 0"/>
    //             </filter>
    //         </defs>`;
    
    //     // Add each line of text to the SVG
    //     lines.forEach((line, i) => {
    //         const y = (i + 1) * lineHeight;

    //         // <text class="${classGenre}" x="50%" y="${y}" text-anchor="middle" font-family="${genreFontFamily}" font-weight="${genreFontWeight}" font-size="${fontSize}" filter="url(#blur)" fill="${genreFontColor}">${line}</text>            

    //         titleSvg += `
    //             <text class="${classGenre}" x="50%" y="${y}" text-anchor="middle" font-size="${fontSize}" filter="url(#blur)" fill="${genreFontColor}">${line}</text>            
    //         `;
    //         // <text x="50%" y="${y}" text-anchor="middle" font-family="${genreFontFamily}" font-weight="${genreFontWeight}" font-size="${fontSize}" filter="url(#solid)" fill="none" stroke="black" stroke-width="1">${line}</text>


    //     });
    
    //     // Close the SVG string
    //     titleSvg += `</svg>`;

    //     // changeFillColor();
    
    //     return titleSvg;

    //     // const bookTitleSvg = document.getElementById(`book-title-svg-${index}`);
    //     // if (bookTitleSvg) bookTitleSvg.innerHTML = titleSvg;
    // }

    // function DeleteBook(event, bookData, index) {
    //     event.stopPropagation();
    //     console.log('Deleting book:', bookData.data.title);
    //     console.log('bookId:', bookData.book_id);
    //     console.log('book: ', bookData);
    //     console.log('bookIndex: ', index);

    //     const bookId = bookData.book_id;
    //     const bookTitle = bookData.data.title;

    //     //create a popup asking the user to confirm the deletion
    //     if (!window.confirm(`Are you sure you want to delete ${bookTitle}?`)) {
    //         return;
    //     }

    //     API.post('/api/story/delete-book', {
    //         userId: userId,
    //         bookId: bookId,
    //         bookData: bookData
    //     }).then((response) => {
    //         console.log(response);
    //         if (response.data.success) {
    //             console.log('Book deleted:', bookTitle);
    //             // // Remove the book from the bookshelf
    //             const newBooks = books.filter(book => book.book_id !== bookId);
    //             setBooks(newBooks);
    //             // remove the book from the booksVisible
    //             // const newVisibleBooks = booksVisible.filter(book => book.book_id !== bookId);
    //             const startIndex = 0;
    //             const endIndex = Math.min(newBooks.length, numVisible);
    //             const newVisibleBooks = newBooks.slice(startIndex, endIndex);

    //             // setBooks(newBooks);
    //             setBooksVisible(newVisibleBooks);
    //             // setIndex(index - 1);
    //             setStartBookIndex(index - 1);
    //         }
    //     })
    //     .catch((error) => {
    //         console.error('Error deleting book:', error);
    //     });
        
    //     //alert window that the book has been deleted
    //     alert(`${bookTitle} has been deleted.`);
    // }
    // ============================================ Page functions ============================================

    return (
        <>
            {booksVisible ? (
                <>
                    <div className={`bookshelf ${(thisLandingShelf || landingShelfOn) ? 'landing-shelf' : ''}`} id='bookshelf' {...handlers} ref={refPassthrough}>
                        <>
                            {booksVisible.map((book, bookIndex) => (
                                <section>
                                    
                                    <BookCover key={bookIndex} summaryCover={false} book={book} bookIndex={bookIndex}/>

                                    {/* <div key={book.book_id}
                                        className="book-cover"
                                        onClick={() => handleBookClick(book.book_id)}
                                        style={{background: `linear-gradient(to top, #0f2027, #203a4300, #2c536400),
                                            url(${book.data.title_image_blob || book.data.cf_title_image})
                                            no-repeat 50% 50% / cover`}}
                                    >
                                        <div className='book-cover-icons'>
                                            <div className='cover-icons'><ThemeIcons themes={book.data.theme} /></div> 
                                        </div>
    
                                        <span className="book-genre" style={{background: GetGenreColor(book.data.genre)}}>{book.data.genre || "No Genre"}</span>

                                        <div className="book-title">
                                            {book.data.title_svg ? (
                                                <div id={`book-title-svg-${(bookIndex)}`} dangerouslySetInnerHTML={{__html: book.data.title_svg}}></div>
                                            ) : (
                                                <div id={`book-title-svg-${(bookIndex)}`} dangerouslySetInnerHTML={{__html: CreateTitleSVG(book.data.title, bookIndex)}}></div>
                                            )}
                                        </div>
                                        
                                        {(!thisLandingShelf && !landingShelfOn) && (
                                            <div className='delete-button' onClick={(event) => DeleteBook(event, book, bookIndex)}>{trashCan}</div>
                                        )} 
                                    </div> */}
                                    

                                    <div className="blur-filter">
                                        <p className={`book-summary ${(thisLandingShelf || landingShelfOn) ? 'landing-shelf' : ''}`}>
                                            {(!thisLandingShelf && !landingShelfOn) && (
                                                book.data.summary
                                            )}
                                        </p>
                                    </div>

                                    {bookIndex+1}:{booksVisible.length}

                                </section>
                            ))}
                        </>
                        <div className={`paginate-controls ${(thisLandingShelf || landingShelfOn) ? 'landing-shelf' : ''}`}>
                            <ReactPaginate ref={paginateRef}                                
                                pageCount={totalPages}
                                onPageChange={handlePageChange}
                                // forcePage={currentPage}
                                forcePage={index}
                                previousLabel={"<"}
                                nextLabel={">"}
                                containerClassName={"pagination"}
                                pageClassName={"paginate-el"}
                                pageLinkClassName={"paginate-link"}
                                breakLabel={""}
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                    {/* <div id="controls"> */}
                    
                    {/* </div> */}
                    {/* <div className='faded'>
                        <div id="controls">
                            <ReactPaginate ref={paginateRef}
                                pageCount={totalPages}
                                onPageChange={handlePageChange}
                                // forcePage={currentPage}
                                // forcePage={index}
                                previousLabel={"<"}
                                nextLabel={">"}
                                containerClassName={"pagination"}
                                pageClassName={"paginate-el"}
                                pageLinkClassName={"paginate-link"}
                                breakLabel={""}
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div> */}
                </>
            ) : (
                <div className="bookshelf">
                    <div className="book">
                        <h3>No books yet!</h3>
                    </div>
                </div>
            )}
        </>
    );
};

export default BookShelfPage;