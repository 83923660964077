import React, { useState, useEffect } from 'react';

import { useStory } from '../context/StoryContext';
import { useStoryPage } from '../context/StoryPageContext'; 

import { bookSchema } from '../account/BookSchema';
import { demo_book_data } from './DemoStory';

// import BookLink from '../modules/BookLink';
// import SkeletonLoader from '../modules/SkeletonLoader';
// import PageImage from './interbook/PageImage';
import StoryPage from './book/StoryPage';

// import getStructuredBook from './getStructuredBook'; // import the Node.js module

import API from '../utils/api_axios';

// import './story-book-page.css';

// const navArrowLeft = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="#8b8b8b"></path> </g></svg>;
// const navArrowRight = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="#8b8b8b"></path> </g></svg>;

import { __DEV__ } from '../DEV';

function StoryGenBookPage() {
    // Context imports
    const { 
        imageGenerator,
        storyPrompt, 
        storySummary, 
        storyTitle,
        titleSvg,
        titleImageUrl,
        titleImageKey,
        bookId,
        setBookId,
        bookLayout, 
        setBookLayout, 
        setStoryComplete, 
        activeProfileId,
        loggedIn,
        userId,
        animateImages,
        setAnimateImages,
        generateContent,

        storyGenre,
        storyTheme,
        storySetting,
        storyCharacters
    } = useStory();

    const {
        currentPage,
        setCurrentPage,
        pageImageUrl,
        setPageImageUrl,
        pageAnimatedImageUrl,
        setPageAnimatedImageUrl,
        pageText,
        setPageText,
        pageNum,
        setPageNum,
        totalPages,
        setTotalPages
    } = useStoryPage();

    // Local state
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    const [hasBeenGenerated, setHasBeenGenerated] = useState(false);
    const [book, setBook] = useState(null);
    // const [currentPage, setCurrentPage] = useState(null);
    // const [pageImageUrl, setPageImageUrl] = useState(null);
    // const [pageAnimatedImageUrl, setPageAnimatedImageUrl] = useState(null);
    // const [pageText, setPageText] = useState(null);
    // const [pageNum, setPageNum] = useState(null);
    // const [totalPages, setTotalPages] = useState(null);
    const [bookPages, setBookPages] = useState(null);
    
    // ========================================= Page StoryGen useEffect =========================================
    // const getCompleteStory = () => {
    useEffect(() => {
        if (animateImages) {
            alert(`Initializing the story generation process with animated images...`);
        }

    // setHasBeenCalled(true)


        const kidsTalesLogo = document.getElementById('nav-container');
        kidsTalesLogo.classList.add('small');
        kidsTalesLogo.classList.remove('shrink');
        
        // Hide the profiles on the story page
        const profiles = document.getElementsByClassName("profiles-container");
        for (let i = 0; i < profiles.length; i++) {
            profiles[i].style.display = "none";
        }

        // alert(`Intializing the story generation process...`);
        console.log("GetCompleteStory");
        console.log("activeProfileId:", activeProfileId);
        console.log("loggedIn:", loggedIn);
        console.log("hasBeenGenerated:", hasBeenGenerated);
        if (__DEV__) {
            LoadDemoBook();
        } else if (!__DEV__ && generateContent && !hasBeenGenerated && activeProfileId && loggedIn) {
            console.log("activeProfileId:", activeProfileId);
            const profileId = activeProfileId;

            const bookData = bookSchema;

            console.log("storygen book page imageGenerator:", imageGenerator);
            console.log("storygen book page imageGenerator.current:", imageGenerator.current);

            bookData.user_id = userId;
            bookData.profile_id = profileId;
            bookData.image_generator = imageGenerator;
            bookData.title = storyTitle;
            bookData.title_svg = titleSvg;
            bookData.summary = storySummary;
            bookData.prompt = storyPrompt;
            bookData.genre = storyGenre;
            bookData.theme = storyTheme;
            bookData.setting = storySetting;
            bookData.characters = storyCharacters;
            
            // bookData.title_image_blob = titleImageUrl;
            bookData.cf_title_image = titleImageUrl;
            bookData.cf_title_key = titleImageKey;

            API.post('/api/story/storygen', {
                userId: userId,
                profileId: profileId, 
                bookData: bookData
            }).then((response) => {
                console.log(response);
                const _completeStory = response.data.completeStory;
                const _expandedStory = response.data.expandedStory;
                const _bookLayout = response.data.bookLayout;
                const _bookId = response.data.bookId;
            
                if (_completeStory) {
                    console.log(_completeStory);
                    // alert(completeStory);
                }

                if (_expandedStory) {
                    console.log(_expandedStory);
                    setStoryComplete(_expandedStory);
                    // setHasBeenGenerated(true);
                    // alert(expandedStory);
                }

                if (_bookLayout) {
                    console.log(_bookLayout);
                    setBookLayout(_bookLayout);
                    // alert(bookLayoutData);
                }

                if (_bookId) {
                    console.log(_bookId);
                    setBookId(_bookId);
                    // alert(bookId);
                    // setHasBeenGenerated(true);
                    // CompleteBookGen(bookId, bookData);
                }

                if (_completeStory && _expandedStory && _bookLayout && _bookId) {
                    console.log("setHasBeenGenerated(true)...");
                    setHasBeenGenerated(true);
                }

                console.log(`BookId: ${bookId} has been generated. Getting page images...`);
            })
            .catch((error) => {
                console.error('Error fetching story book content:', error);
                setHasBeenGenerated(false);
            });

        }
        // else if (!loggedIn) {
        else {
            alert(`You need to be logged in to generate a story.`);
            // setPrompts(demo_prompts);
        }
    // };
    }, []);
    // ========================================= Page useEffect =========================================

    function LoadDemoBook() {
        console.log("LoadDemoBook");

        setBookId("demo-book-id-1");

        try {
            if (!hasBeenCalled) {
                API.post('/api/public/get-public-book', { 
                    bookId: "demo-book-id-1",
                }).then((response) => {
                    console.log(response);
                    if (response.data.bookData) {
                        const _bookData = response.data.bookData;

                        //storygen data
                        setStoryComplete(_bookData.expandedStory);
                        setBookLayout(_bookData.bookLayout);                
                        setHasBeenGenerated(true);
    
                        const pageData = _bookData.pages[1];
                        setTotalPages(_bookData.pages.length);
                        console.log(pageData);
                        console.log(pageData.cf_cloud_url || pageData.cloud_url);
                        setPageImageUrl(pageData.cf_cloud_url || pageData.cloud_url);
                        // setPageAnimatedImageUrl(pageDataModel.cf_cloud_animated_url);
                        console.log(pageData.page_text);
                        setPageText(pageData.page_text);
                        console.log(pageData.page_number);
                        setPageNum(pageData.page_number); //added
                        setCurrentPage(pageData.page_number) //added
                        // console.log("currentPage:", currentPage);
                        console.log("pageNum:", pageNum);
                        console.log("totalPages:", totalPages);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching first page of book:', error);
                });
    
                setHasBeenCalled(true);
            } else {
                // console.log("currentPage:", currentPage);
                console.log("pageNum:", pageNum);
                console.log("totalPages:", totalPages);
            }
        } catch (error) {
            console.error('Error fetching demo book:', error);
        }

        console.log("DemoBook has been loaded...");
    }


    // useEffect(() => {
    // // document.addEventListener('DOMContentLoaded', () => {
    //     if (pageImageUrl) {
    //         const container = document.getElementById('book-page-image');
    //         let isPanning = false;
    //         let startX = 0;
    //         let startY = 0;
    //         let x = 0;
    //         let y = 0;

    //         // Function to start panning
    //         const startPanning = (clientX, clientY) => {
    //             isPanning = true;
    //             container.style.cursor = 'grabbing'; // Might not be visible on touch devices
    //             startX = clientX - x;
    //             startY = clientY - y;
    //         };

    //         // Update the event handlers to handle both mouse and touch events
    //         const onMouseDown = (e) => {
    //             startPanning(e.clientX, e.clientY);
    //             e.preventDefault(); // Prevent default to avoid any text selection etc.
    //         };

    //         const onTouchStart = (e) => {
    //             const touch = e.touches[0];
    //             startPanning(touch.clientX, touch.clientY);
    //             e.preventDefault(); // Prevent scrolling while touching
    //         };

    //         const onMove = (clientX, clientY) => {
    //             if (!isPanning) return;
    //             x = clientX - startX;
    //             y = clientY - startY;
    //             container.style.transform = `translate(${x}px, ${y}px)`;
    //         };

    //         const onMouseMove = (e) => {
    //             onMove(e.clientX, e.clientY);
    //         };

    //         const onTouchMove = (e) => {
    //             const touch = e.touches[0];
    //             onMove(touch.clientX, touch.clientY);
    //         };

    //         const stopPanning = () => {
    //             isPanning = false;
    //             container.style.cursor = 'grab';
    //         };

    //         // Adding both mouse and touch event listeners
    //         container.addEventListener('mousedown', onMouseDown);
    //         container.addEventListener('mousemove', onMouseMove);
    //         container.addEventListener('mouseup', stopPanning);
    //         container.addEventListener('mouseleave', stopPanning);

    //         container.addEventListener('touchstart', onTouchStart);
    //         container.addEventListener('touchmove', onTouchMove);
    //         container.addEventListener('touchend', stopPanning);
    //         container.addEventListener('touchcancel', stopPanning);

    //         // Cleanup function to remove event listeners when component unmounts
    //         return () => {
    //             container.removeEventListener('mousedown', onMouseDown);
    //             container.removeEventListener('mousemove', onMouseMove);
    //             container.removeEventListener('mouseup', stopPanning);
    //             container.removeEventListener('mouseleave', stopPanning);

    //             container.removeEventListener('touchstart', onTouchStart);
    //             container.removeEventListener('touchmove', onTouchMove);
    //             container.removeEventListener('touchend', stopPanning);
    //             container.removeEventListener('touchcancel', stopPanning);
    //         };
    //     }
    
    // }, [pageImageUrl]);

    // ========================================= BookGen useEffect =========================================
    // 1.24.24 - 0100: TEMP TURNED OFF _ TURN BACK ON FOR UPDATING BOOK
    // function CompleteBookGen(bookId, bookData) {
    useEffect(() => {
        console.log("StoryBookGen useEffect");
        console.log("activeProfileId:", activeProfileId);
        console.log("loggedIn:", loggedIn);
        console.log("hasBeenCalled:", hasBeenCalled);
        console.log("bookLayout:", bookLayout);
        //Data loaded with LoadDemoBook
        // if (__DEV__) {
        //     pass;
        // } else 
        if (!__DEV__ && generateContent && !hasBeenCalled && loggedIn && activeProfileId && userId) {
            console.log("bookLayout:", bookLayout);
            const prompt = storyPrompt;

            // const bookLayout = bookData.book_layout;
            //count the number of pages in the book
            if (!bookLayout) {
                console.log("No book layout data.");
                return;
            }

            console.log("bookLayout:", bookLayout);
            setTotalPages(bookLayout.length + 1); //for last The End page
            console.log("TotalPages:", totalPages);

            API.post('/api/story/bookgen', { 
                userId: userId, 
                bookId: bookId,
                animateImages: animateImages
            }).then((response) => {
                console.log(response);
                if (response.data.pageDataModel) {
                    const pageDataModel = response.data.pageDataModel;
                    console.log(pageDataModel);
                    setPageImageUrl(pageDataModel.cf_cloud_url);
                    setPageAnimatedImageUrl(pageDataModel.cf_cloud_animated_url);
                    setPageText(pageDataModel.page_text);
                    setPageNum(pageDataModel.page_number);
                    setCurrentPage(pageDataModel.page_number);
                    // SetFullscreenImage(pageDataModel.cf_cloud_url);

                    console.log("setHasBeenCalled(true)...");
                    setHasBeenCalled(true);
                }
            })
            .catch((error) => {
                console.error('Error fetching illustrated book:', error);
            });

            // 1.24.24 - 0100: TEMP TURNED OFF _ TURN BACK ON TO PREVENT RERUNNING
            // setHasBeenCalled(true);
        } else if (!loggedIn) {
            //nothing for now, this is for non logged in users
            console.log("User not logged in.");
        }
    // };
    }, [bookId, bookLayout]);
    // ========================================= BookGen useEffect =========================================


    // ========================================= Page functions =========================================
    // function GetPrevPage() {
    //     const getPageNum = pageNum - 1;
    //     if (getPageNum > 0) {
    //     GetPage(getPageNum);
    //     }
    // };

    // function GetNextPage() {
    //     const getPageNum = pageNum + 1;
    //     if (getPageNum <= totalPages) {
    //     GetPage(getPageNum);
    //     }
    // };

    // function GetPage(getPageNum) {
    //     // setPageImageUrl("");
    //     // setPageText("");
    //     // setPageNum(getPageNum);
    //     // setCurrentPage(getPageNum);


    //     API.post('/api/story/get-page', { 
    //         bookId: bookId,
    //         userId: userId,
    //         pageNum: getPageNum,
    //     }).then((response) => {
    //         console.log(response);
    //         if (response.data.pageData) {
    //             const pageData = response.data.pageData;
    //             console.log(pageData);
    //             console.log("NextPage");
    //             console.log("currentPage:", currentPage);
    //             console.log("pageNum:", pageNum);
    //             console.log("totalPages:", totalPages);
    //             if ((currentPage <= totalPages) || (pageNum <= totalPages)) {
    //                 if (pageData.page_number === getPageNum) {
    //                     console.log(pageData.cf_cloud_url);
    //                     setPageImageUrl(pageData.cf_cloud_url);
    //                     console.log(pageData.cf_cloud_animated_url);
    //                     setPageAnimatedImageUrl(pageData.cf_cloud_animated_url);
    //                     console.log(pageData.page_text);
    //                     setPageText(pageData.page_text);
    //                     console.log(pageData.page_number);
    //                     setPageNum(pageData.page_number);
    //                     setCurrentPage(pageData.page_number)
    //                     // SetFullscreenImage(pageData.cf_cloud_url);
    //                 }
    //                 console.log("currentPage:", currentPage);
    //                 console.log("pageNum:", pageNum);
    //                 console.log("totalPages:", totalPages);
    //             }
    //         }
    //     })
    //     .catch((error) => {
    //         console.error('Error fetching next page:', error);
    //     });

    //     // setCurrentPage(currentPage + 1);
    //     // }
    // };

    // function SetFullscreenImage(imageUrl) {
    //     const bookPageImageContainer = document.getElementById('book-page-image');
    //     bookPageImageContainer.style.background = `url(${imageUrl}) no-repeat 50% 50% / cover`;
    // }

    

    // function SetFullscreenImage(imageUrl) {
    //     const bookPageContainer = document.getElementById('book-page');
    //     bookPageContainer.style.background = `url(${imageUrl}) no-repeat 50% 50% / cover`;
    //     bookPageContainer.style.backgroundAttachment = 'fixed'; // Make the background fixed
    //     bookPageContainer.style.backgroundSize = 'contain'; // Adjust the background size
    //     bookPageContainer.style.backgroundPosition = 'center'; // Center the background
    //     bookPageContainer.style.overflow = 'auto'; // Enable scrolling within the container
    // }
    
    // ========================================= Page functions =========================================


    return (
        <StoryPage />
    );
}

export default StoryGenBookPage;


////
// We have the "Get whole story button", use that button to initiate the backend generation of the whole
// story, pass the whole story to the editor and get the breakdown, return the breakdown to the front end, 
// but also start the image generation for everything, return each image and upload them to the cloudflare
// after the button is hit, we are sent to the storybook skeleton page with a activeUser toggle where we wait
// for the story data to come back, for the first page, if the user leaves the page, the story and all it's
// associated data is not passed to the fornt end, the story is returned to the front end if the user stays 
// in the app, if they close the page, the activeUser is closed on the backend and the story is just saved,
// a notification is sent when the story is available to view. Only one story per StoryId can be created