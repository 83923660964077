import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaExpand, FaExpandAlt, FaExpandArrowsAlt } from 'react-icons/fa';

import { CreateTitleSVG } from './BookUtils';

import { useShelf } from '../../context/BookShelfContext';
import { useStory } from '../../context/StoryContext';
import { useStoryPage } from '../../context/StoryPageContext';


import SkeletonLoader from '../../modules/SkeletonLoader';

import API from '../../utils/api_axios';

import './book.css';

const navArrowLeft = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="#8b8b8b"></path> </g></svg>;
const navArrowRight = <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="#8b8b8b"></path> </g></svg>;

const StoryBookPage = () => {
// function StoryBookPage() {
    // Context imports
    const { setUserId, setBookId } = useStory();
    const { pageNum, setPageNum, totalPages, setTotalPages } = useStoryPage();
    const { landingShelfOn, setLandingShelfOn } = useShelf();

    let { userId, bookId } = useParams(); // Get the userId and bookId from the URL
    console.log("StoryBookPage");
    console.log("userId:", userId);
    console.log("bookId:", bookId);

    // Page state
    // var activePage = 0;
    var book = document.getElementById('book');;
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    // const [currentPage, setCurrentPage] = useState(0);
    const [pageImageUrl, setPageImageUrl] = useState(null);
    const [pageText, setPageText] = useState(null);
    // const [pageNum, setPageNum] = useState(null);
    // const [totalPages, setTotalPages] = useState(null);
    const [bookData, setBookData] = useState(null);
    
    // document.addEventListener('DOMContentLoaded', () => {
    //   book = document.getElementById('book');
    // });

    // ========================================= Page useEffect =========================================
    useEffect(() => {
        const kidsTalesLogo = document.getElementById('nav-container');
        // kidsTalesLogo.classList.add('shrink');
        // kidsTalesLogo.classList.remove('shrink');
        kidsTalesLogo.classList.add('transparent');
        kidsTalesLogo.classList.add('small');


        // Hide the profiles on the story page
        const profiles = document.getElementsByClassName("profiles-container");
        for (let i = 0; i < profiles.length; i++) {
            profiles[i].style.display = "none";
        }

        

        if (!hasBeenCalled) {
            API.post('/api/public/get-book', { 
                bookId: bookId,
                userId: userId,
            }).then((response) => {
                console.log(response);
                if (response.data.bookData) {
                    const _bookData = response.data.bookData;
                    setBookData(_bookData);

                    const pageData = _bookData.pages[0];
                    setTotalPages(_bookData.pages.length);
                    console.log(pageData);
                    console.log(pageData.cf_cloud_url || pageData.cloud_url);
                    setPageImageUrl(pageData.cf_cloud_url || pageData.cloud_url);
                    console.log(pageData.page_text);
                    setPageText(_bookData.summary);
                    console.log(pageData.page_number);
                    // setPageNum(pageData.page_number);
                    // setCurrentPage(pageData.page_number)
                    // console.log("currentPage:", currentPage);
                    console.log("pageNum:", pageNum);
                    console.log("totalPages:", totalPages);
                }
            })
            .catch((error) => {
                console.error('Error fetching first page of book:', error);
            });

            setHasBeenCalled(true);
        } else {
            // console.log("currentPage:", currentPage);
            console.log("pageNum:", pageNum);
            console.log("totalPages:", totalPages);
        }

        // setCurrentPage(currentPage + 1);
        // }
    }, []);
    // ========================================= Page useEffect =========================================

    // ========================================= bookData useEffect =========================================  
    //   function CreatePages() {
    useEffect(() => {
        if (!bookData) {
            return;
        }

        setLandingShelfOn(false);
        // const landingShelf = document.getElementById('landing-shelf-container');
        // if (landingShelf) {
        //   landingShelf.style.display = 'none';
        // }

        const page_data = bookData.pages;
        console.log("Creating pages...");
        console.log(page_data);
        var i = 0;

        SetBackgroundImage(bookData.title_image_blob || bookData.cf_title_image);
        
        for (var page_content of page_data) {
            const page = document.createElement("div");
            page.className = "page";
            page.id = `Page${i+1}`;
            
            // const pageText = document.createElement("div");
            // pageText.className = "page-text";
            // pageText.innerText = page_content.page_text;
            // page.appendChild(pageText);

            // page.innerText = page_content.page_text;
            
            // page.backgroundImage = page_content.cloud_url;
            if (page_content.page_text === "The End") {
                page.style.background = '#f1f1f1';
            } else {
                page.style.background = `url(${page_content.cf_cloud_url || page_content.cloud_url}) no-repeat 50% 50% / cover`;
            }

            page.style.zIndex = -i;
            if (i === 0 || i%2 === 0) {
                page.classList.add("back");
            } else {
                page.classList.add("front");
            }

            book.appendChild(page);
            i++
        }

        const frontCover = document.getElementById("cover-front");
        if (bookData.title_svg == null) {
            frontCover.innerText = bookData.title;
        }

        frontCover.style.background = `url(${bookData.title_image_blob || bookData.cf_title_image}) no-repeat 50% 50% / cover`;
    
        const backCover = document.createElement("div");
        backCover.className = "page cover";
        backCover.id = `cover-back`;
        backCover.innerText = "Back Cover"
        backCover.style.zIndex = -i -100;
        book.appendChild(backCover);
    
        console.log("Done creating pages.");
    //   }
    }, [bookData]);
    // ========================================= bookData useEffect =========================================  

  
    // ========================================= Page functions =========================================  
    function StackPages(pages) {
        var i = 0;
        for (var page of pages) {
            console.log(page);
            page.style.zIndex = -i;
            i++;
        }
    }
  
    function OpenBook(pages) {
        const frontCover = document.getElementById("cover-front");
        frontCover.classList.add("open");
        //   frontCover.style.backdropFilter = "blur(10px)";

        const backCover = document.getElementById("cover-back");
        backCover.classList.add("open");
        TurnNextPage(pages);
    }
    
    function NextPage() {
        console.log("NextPage...");
        const pages = document.querySelectorAll('.page');
        console.log("Next Page, leaving page: " + pageNum);
    
        if (pageNum == 0) {//Cover page
            ChangeBackgroundImage(pageNum);
            OpenBook(pages);
            setPageText(bookData.pages[pageNum].page_text);
        } else if (pageNum < pages.length -2) {//Page before last page
            if (pageNum%2 == 0) {
                TurnNextPage(pages);
                const pageIndex = pageNum-1;
                ChangeBackgroundImage(pageNum);
                setPageText(bookData.pages[pageNum].page_text);
            } else {
                SlideBookLeft(false);
                ActivateAnimatedPage("next", pageNum);
                ChangeBackgroundImage(pageNum);
                // activePage++;
                setPageNum((prevValue) => prevValue + 1);
                setPageText(bookData.pages[pageNum].page_text);
            }
        } else {
            setPageText(null);
            CloseBook(pages);
            StackPages(pages);
        }

        //   console.log(pages);
        //   if (pageNum === pages.length - 2) {
        //       alert("You have reached the end of the book.");
        //   }
    
        console.log(`Now viewing page: ${pageNum}`);
    }

    function ActivateAnimatedPage(direction, pageIndex) {
        const pages = document.querySelectorAll('.page');

        if (direction === "next") {
            const oldPage = pages[pageIndex];
            const newPage = pages[pageIndex+1];
            oldPage.classList.remove("animated");
            newPage.classList.add("animated");
            // pageFront.style.zIndex = (-pages.length + pageIndex);
            // pageBack.style.zIndex = (-pages.length + pageIndex + 1);
            // pageFront.style.transform = 'rotateY(-180deg)';
            // pageBack.style.transform = 'rotateY(0deg)';
        } else if (direction === "prev")  {
            const oldPage = pages[pageIndex];
            const newPage = pages[pageIndex-1];
            oldPage.classList.remove("animated");
            newPage.classList.add("animated");
            // pageFront.style.zIndex = -pageIndex;
            // pageBack.style.zIndex = -pageIndex + 1;
            // pageFront.style.transform = 'rotateY(0deg)';
            // pageBack.style.transform = 'rotateY(180deg)';
        }
    }

    function ChangeBackgroundImage(pageIndex) {
        const storyBookPageContainer = document.getElementById('story-book-page');
        const backgroundImage = bookData.pages[pageIndex].cf_cloud_url || bookData.pages[pageIndex].cloud_url;
        storyBookPageContainer.style.background = `url(${backgroundImage}) no-repeat 50% 50% / cover`;
        // storyBookPageContainer.style.backdropFilter = "blur(10px)";
    }

    function SetBackgroundImage(imageUrl) {
        const coverContainer = document.getElementById('story-book-page');
        coverContainer.style.background = `url(${imageUrl}) no-repeat 50% 50% / cover`;
        // coverContainer.style.backdropFilter = "blur(10px)";  
    }
  
    function TurnNextPage(pages) {
        SlideBookRight(false);

        // ChangeBackgroundImage(pageNum+1);
        ActivateAnimatedPage("next", pageNum);

        const pageFront = pages[pageNum];
        pageFront.classList.add("open")
        const pageBack = pages[pageNum+1];
        pageBack.classList.add("open")
        pageBack.style.zIndex = (-pages.length + pageNum + 1);
        pageFront.style.zIndex = (-pages.length + pageNum);
        pageBack.style.transform = 'rotateY(0deg)';
        pageFront.style.transform = 'rotateY(-180deg)';
        setPageNum((prevValue) => prevValue + 1);
        // activePage++;
    }
    
    function PrevPage() {
        console.log("PrevPage...");
        const pages = document.querySelectorAll('.page');
        console.log("Prev Page, leaving page: " + pageNum);
    
    
        if (pageNum == 0) {//Cover page
            ChangeBackgroundImage(pageNum);
            OpenBook(pages);
            if (pageNum-2 >= 0) setPageText(bookData.pages[pageNum-2].page_text);
        } else if (pageNum < pages.length) {
            console.log(`pageNum: ${pageNum}, go to prev page`);
            if (pageNum%2 == 0) {
                ActivateAnimatedPage("prev", pageNum);
                SlideBookRight(false);
                ChangeBackgroundImage(pageNum-2);
                //   const pageIndex = pageNum-1;
                //   ChangeBackgroundImage(pageNum+1);
                // activePage--;
                if (pageNum-2 >= 0) setPageText(bookData.pages[pageNum-2].page_text);
                setPageNum((prevValue) => prevValue - 1);
            } else {
                if (pageNum === 1) {
                    // ActivateAnimatedPage("prev", pageNum);
                    SlideBookLeft(true);
                } else {
                    // ActivateAnimatedPage("prev", pageNum);
                    SlideBookLeft(false);
                    ChangeBackgroundImage(pageNum-2);
                }
                if (pageNum-2 >= 0) setPageText(bookData.pages[pageNum-2].page_text);
                TurnPrevPage(pages);
            }
        }

        if (pageNum === 0) {
            SetBackgroundImage(bookData.title_image_blob || bookData.cf_title_image);
        }
    
        console.log(`Now viewing page: ${pageNum}`);
    }

    // useEffect(() => {
    //     if (pageNum === 0) {
    //         ChangeBackgroundImage(pageNum);
    //     }
    // }, [pageNum]);
    
    function TurnPrevPage(pages) {
        // ChangeBackgroundImage(activePage-1);

        ActivateAnimatedPage("prev", pageNum);

        const pageFront = pages[pageNum-1];
        pageFront.classList.remove("open");
        const pageBack = pages[pageNum];
        pageBack.classList.remove("open");
        pageBack.style.zIndex = -pageNum;
        pageBack.style.transform = 'rotateY(180deg)';
        pageFront.style.transform = 'rotateY(0deg)';
        pageFront.style.zIndex = -pageNum + 1;
        // activePage--;
        setPageNum((prevValue) => prevValue - 1);
    }
    
    function CloseBook(pages) {
        SlideBookLeft(true);
        var i = pages.length-1;
        for (var page of pages) {
            setTimeout(UnturnPage, i*150, page);      
            i--;
        }
        const backCover = document.getElementById("cover-back");
        backCover.classList.remove("open");
        // activePage = 0;
        setPageNum(0);
        SetBackgroundImage(bookData.title_image_blob || bookData.cf_title_image);

        const frontCover = document.getElementById("cover-front");
        frontCover.innerText = bookData.title;
        frontCover.style.background = `url(${bookData.title_image_blob || bookData.cf_title_image}) no-repeat 50% 50% / cover`;
    }
    
    function UnturnPage(page) {
        console.log("TimeoutEnd");
        if (page.classList.contains("back")) {
            page.style.transform = 'rotateY(180deg)';
        } else {
            page.style.transform = 'rotateY(0deg)';
        }
        page.classList.remove("open");
    }
    
    function SlideBookRight(closed) {
        if (closed === true) {
            book.style.translate = '100%';
        } else {
            book.style.translate = 'calc(100% - 5px)';
        }
    }
    
    function SlideBookLeft(closed) {
        if (closed === true) {
            book.style.translate = '0';
        } else {
            book.style.translate = '5px';
        }
    }

    function ExpandedView() {
        setUserId(userId);
        setBookId(bookId);
        setPageNum(pageNum != 0 ? pageNum : 1);
    }
    // ========================================= Page functions =========================================  

    return (
        <div className="story-book-page" id="story-book-page">
            {/* <div className='get-link'>
                <BookLink width="40px" height="40px" userId={userId} bookId={bookId}/>
            </div> */}
            <div className='get-link'>
                {/* <BookLink width="40px" height="40px" userId={userId} bookId={bookId}/> */}
                <Link className='button-link' to={`/story-page`} onClick={() => ExpandedView()}>
                    <button className="neu-send-button">
                        <FaExpand />
                    </button>
                </Link>
            </div>
            <div className='book-container'>
                <div id="device-view"></div>
                <div id="book">
                    {/* <div className="page cover" id="cover-front">Cover</div> */}
                    {bookData ? 
                        <div className="page cover" id="cover-front">
                            <div className="book-title svg" id={`book-title-svg`} dangerouslySetInnerHTML={{__html: bookData.title_svg || CreateTitleSVG(bookData.title, bookData.genre)}}></div>
                        </div>
                        :
                        <div className="page cover" id="cover-front"></div>
                    }
                    {/* <div id="device-view"></div> */}
                </div>
                <div className="page-nav">
                    <div className="page-nav-dir" id="page-nav-prev" onClick={() => PrevPage()}></div>
                    <div className="page-nav-dir" id="page-nav-next" onClick={() => NextPage()}></div>
                </div>
            </div>
            <div className='page-text'>
                {pageText}
            </div>
            <div className='page-num'>{pageNum}/{totalPages}</div>

        </div>
    );
}

export default StoryBookPage;