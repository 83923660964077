import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useStory } from '../context/StoryContext';
import { useUser } from '../context/UserContext';

import API from '../utils/api_axios';

import './account-page.css'; // Make sure to create and import the CSS for styling

const AccountPage = () => {
    const navigate = useNavigate();

    const { userId, userProfile, setUserProfile, loggedIn, setLoggedIn } = useStory();
    const { credits, setCredits } = useUser();

    // Assuming user information is fetched or stored in state
    // const [userInfo, setUserInfo] = useState({ name: 'John Doe', email: 'john@example.com' });
    // const [generationPin, setGenerationPin] = useState('1234'); // Dummy initial PIN
    const [oldPin, setOldPin] = useState('');
    const [newPin, setNewPin] = useState('');
    const [newPin2, setNewPin2] = useState('');
    // const [credits, setCredits] = useState(0); // Initialize credits state
    const [subscription, setSubscription] = useState('Free'); // Initialize subscription state
    const [showPinForm, setShowPinForm] = useState(false); // State to control the visibility of the PIN change form
    const [showSubscriptionForm, setShowSubscriptionForm] = useState(false); // State to control the visibility of the subscription change form
    const [showCancelForm, setShowCancelForm] = useState(false);
    const [lastLogin, setLastLogin] = useState(null);

    useEffect(() => {
        if (userProfile) {
            console.log('loginPage loggedIn:', loggedIn);
            console.log('loginPage userProfile:', userProfile);
        }

        const profilesContainer = document.getElementById('profiles-container');
        if (profilesContainer) {
            profilesContainer.style.display = 'none';
        }

        const demoProfilesContainer = document.getElementById('demo-profiles-container');
        if (demoProfilesContainer) {
            demoProfilesContainer.style.display = 'none';
        }

        // Get user info if they are already logged in
        const userToken = localStorage.getItem('userToken');
        setLoggedIn(localStorage.getItem('loggedIn'));

        if (loggedIn && userToken) {
            API.post(`/api/user/get-user`, {
                userToken: userToken
            })
                .then((response) => {
                    const profileData = response.data.profileData;
                    console.log("profileData.name: " + profileData.name);
                    console.log("profileData.picture: " + profileData.picture);
                    console.log("profileData.email: " + profileData.email);
                    console.log("profileData.user_id: " + profileData.user_id);
                    console.log("profileData.last_login: " + profileData.last_login);
                    setUserProfile(profileData);
                    setLastLogin(profileData.last_login);
                    setLoggedIn(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        // Fetch user credits
        API.post(`/api/user/get-credits`, { user_id: userId })
            .then((response) => {
                setCredits(response.data.creditsData);
            })
            .catch((error) => {
                console.log('Error fetching user credits:', error);
            });

        // Fetch user subscription
        API.get(`/api/user/get-account-type`, { user_id: userId })
            .then((response) => {
                // setSubscription(response.data.subscriptionData);
                setSubscription(response.data.accountType);
            })
            .catch((error) => {
                console.log('Error fetching user subscription:', error);
            });
    }, []);

    function showPinSuccess(msg) {
        const pinMsg = document.getElementById('pin-msg');
        pinMsg.textContent = msg;
        pinMsg.style.display = 'block';

        setTimeout(() => {
            pinMsg.textContent = '';
            pinMsg.style.display = 'none';
        }, 5000);
    }

    const updateGenPin = () => {
        if (newPin !== newPin2) {
            console.log('New PINs do not match!');
            showPinSuccess('New PINs do not match!');
            setNewPin('');
            setNewPin2('');
            return;
        }

        // Assuming you have an API endpoint to update the PIN in the database
        API.post(`/api/user/update-pin`, {
            user_id: userId,
            old_pin: oldPin,
            new_pin: newPin
        })
            .then((response) => {
                console.log('updatePinResp: ', response);
                console.log('PIN updated successfully!');
                setOldPin('');
                setNewPin('');
                setNewPin2('');
                showPinSuccess('Generation PIN changed successfully!');
            })
            .catch((error) => {
                console.log('Error updating PIN:', error);
            });
    };
    const showPricingPage = () => {
        // Navigate to the pricing page
        navigate('/pricing');
    };

    // const changeSubscription = (newSubscription) => {
    //     // Assuming you have an API endpoint to change the subscription in the database
    //     API.post(`/api/user/change-subscription`, {
    //         user_id: userId,
    //         new_subscription: newSubscription
    //     })
    //         .then((response) => {
    //             console.log('changeSubscriptionResp: ', response);
    //             console.log('Subscription changed successfully!');
    //             setSubscription(newSubscription);
    //         })
    //         .catch((error) => {
    //             console.log('Error changing subscription:', error);
    //         });
    // };
    const changeSubscription = () => {
        // This function now just redirects to the pricing page
        showPricingPage();
    };

    const cancelSubscription = () => {
        setShowCancelForm(true);
    };

    const confirmCancelSubscription = () => {
        API.post(`/api/cart/cancel-subscription`, { userId })
            .then((response) => {
                console.log('Subscription cancelled successfully');
                setSubscription('Free');
            })
            .catch((error) => {
                console.error('Error cancelling subscription:', error);
            })
            .finally(() => {
                setShowCancelForm(false);
            });
    };

    const deleteAccount = () => {
        const confirmDelete = window.confirm("Are you sure you want to delete your account? This action is permanent and will take a couple of days to process.");

        if (confirmDelete) {
            // Assuming you have an API endpoint to delete the account
            API.post(`/api/user/delete-account`, {
                user_id: userId
            })
                .then((response) => {
                    console.log('deleteAccountResp: ', response);
                    console.log('Account deleted successfully!');
                    // Perform any necessary cleanup or redirection here
                })
                .catch((error) => {
                    console.log('Error deleting account:', error);
                });
        }
    };

    return (
        <div className="account-container">
            <h2>Account Settings</h2>
            <div className="account-plate">
                {userProfile ? (
                    <div className="user-info">
                        <div className='profile-picture'>
                            <img src={userProfile.picture !== null ? userProfile.picture : ""} alt="user image" />
                        </div>
                        <h3>{userProfile.name}</h3>
                        <p><strong>Email:</strong> {userProfile.email}</p>
                        <p><strong>Credits:</strong> {credits}</p>
                        <p><strong>Subscription:</strong> {subscription}</p>
                        {lastLogin && (
                            <p><strong>Last Login:</strong> {new Date(lastLogin).toLocaleString()}</p>
                        )}
                    </div>
                ) : (
                    <div className="user-info">
                        <h3>Loading...</h3>
                    </div>
                )}
                <button onClick={() => setShowPinForm(!showPinForm)} className='neu-form-button-small'>
                    {showPinForm ? 'Hide PIN Form' : 'Change PIN'}
                </button>
                {subscription === "Free" ? (
                    <button onClick={() => showPricingPage()} className='neu-form-button-small'>Subscribe</button>
                ) : (
                    <button onClick={() => setShowSubscriptionForm(!showSubscriptionForm)} className='neu-form-button-small'>
                        {showSubscriptionForm ? 'Hide Subscription Options' : 'Manage Subscription'}
                    </button>
                )}
                {showPinForm && (
                    <div className="pin-change-form">
                        <h3>Change Generation PIN</h3>
                        <input
                            className='neu-form-input'
                            type="password"
                            placeholder="Old PIN"
                            value={oldPin}
                            onChange={(e) => setOldPin(e.target.value)}
                            required
                        />
                        <input
                            className='neu-form-input'
                            type="password"
                            placeholder="New PIN"
                            value={newPin}
                            onChange={(e) => setNewPin(e.target.value)}
                            required
                        />
                        <input
                            className='neu-form-input'
                            type="password"
                            placeholder="Repeat New PIN"
                            value={newPin2}
                            onChange={(e) => setNewPin2(e.target.value)}
                            required
                        />
                        <div className='pin-msg' id='pin-msg' style={{display: "none"}}></div>
                        <button className='neu-form-button-small' id='pin-btn' onClick={() => updateGenPin()}>Update PIN</button>
                    </div>
                )}
                {showSubscriptionForm && subscription !== "Free" && (
                    <div className="subscription-change-form">
                        <h3>Manage Subscription</h3>
                        <button className='neu-form-button-small' onClick={() => changeSubscription()}>Change Plan</button>
                        <button className='neu-form-button-small' onClick={() => cancelSubscription()}>Cancel Subscription</button>
                    </div>
                )}
                <hr/>
                <button className='neu-form-button-small' id='delete-btn' onClick={() => deleteAccount()}>Delete Account</button>
            </div>

            {showCancelForm && (
                <div className="floating-confirmation">
                    <h3>Cancel Subscription</h3>
                    <p>Are you sure you want to <strong>cancel</strong> your subscription?</p>
                    <p>You will be able to use your <strong>current plan</strong> until the end of your <strong>billing period</strong>.</p>
                    <div className="floating-confirmation-buttons">
                        <button className="confirm" onClick={confirmCancelSubscription}>Yes, Cancel</button>
                        <button className="cancel" onClick={() => setShowCancelForm(false)}>No, Keep Subscription</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AccountPage;