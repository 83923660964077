import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { useStory } from '../context/StoryContext';
import { useUser } from '../context/UserContext';

import './pricing-page.css';

import { KT_API_URL } from '../utils/api_axios';

const plans = [
  { id: 1, type: "item", priceId: "price_1PEmVMFYyBQwzQ15EXY0QBKO", name: 'Single Adventure', price: '$2.99', features: ['1 AI generated adventure'] },
  { id: 2, type: "subscription", priceId: "price_1PEmVhFYyBQwzQ15YrQ4oO9S", name: 'Weekly', price: '$9.99/mo', features: ['1 new book every week!', '+ Buy additional discounted books'] },
  { id: 3, type: "subscription", priceId: "price_1PEmW4FYyBQwzQ15rajVQoZw", name: 'All-week', price: '$19.99/mo', features: ['7 books, one for each day of the week!', '+2 free extra books for the weekend', '+ Buy additional discounted books'] },
  { id: 4, type: "subscription", priceId: "price_1PEmWVFYyBQwzQ15JhCHWf7F", name: 'Everyday Adventurer', price: '$59.99/mo', features: ['A new story every day!', '+ Buy additional discounted books'] },
];

const PricingPage = () => {
    const { userId } = useStory();
    const { accountType } = useUser();

    const [message, setMessage] = useState("");

    const navigate = useNavigate();
    
    useEffect(() => {
        const navContainer = document.querySelector('.nav-container');
        navContainer.classList.add('small');
        navContainer.classList.remove('transparent');

        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);

    const Checkout = async (checkoutType, priceId, planName) => {
        const response = await fetch(`${KT_API_URL}/api/cart/checkout`, {
            method: 'POST',
            headers: {
              "Content-Type": 'application/json'
            },
            body: JSON.stringify({ userId, priceId, checkoutType, planName })
        })
        const body = await response.json();
        window.location.href = body.url;
        // navigate(body.url); // Navigate to the new route
        // <Link to={body.url} />

    };

    const CancelSub = async () => {
        const response = await fetch(`${KT_API_URL}/api/cart/cancel-subscription`, {
            method: 'POST',
            headers: {
              "Content-Type": 'application/json'
            },
            body: JSON.stringify({ userId })
        })
        const body = await response.json();
        // alert(body.url);
        window.location.href = body.url;
        // <Link to={body.url} />
    };

    const ChangeSub = async (priceId, planName) => {
        const response = await fetch(`${KT_API_URL}/api/cart/change-subscription`, {
            method: 'POST',
            headers: {
              "Content-Type": 'application/json'
            },
            body: JSON.stringify({ userId, priceId, planName })
        })
        const body = await response.json();
        // alert(body.url);
        window.location.href = body.url;
        // <Link to={body.url} />
    };

    const ProductsDisplay = () => (
        <div className="pricing-container">
            {plans.map((plan) => (
                <div key={plan.id} className="card">
                    <div className="card-content">
                        <h2>{plan.name}</h2>
                        <p className="price">{plan.price}</p>
                        <ul>
                        {plan.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                        </ul>
                        {/* <button>Choose Plan</button> */}
                        {/* {(plan.id === 1) ? (
                            <button onClick={() => Checkout('token', plan.priceId)}>Buy Credit</button>
                        ) : (
                            <>
                                <button onClick={() => Checkout('sub', plan.priceId)}>Subscribe</button>
                                <button onClick={() => Checkout('upgrade', plan.priceId)}>Upgrade</button>
                                <button onClick={() => Checkout('downgrade', plan.priceId)}>Downgrade</button>
                            </>
                        )} */}

                        {accountType === 'free' ? (
                            (plan.id === 1) ? (
                                <button onClick={() => Checkout('token', plan.priceId, plan.name)}>Buy Credit</button>
                            ) : (
                                <button onClick={() => Checkout('subscription', plan.priceId, plan.name)}>Subscribe</button>
                            )
                        ) : (
                            (plan.name.toLowerCase() === accountType) ? (
                                <button onClick={() => CancelSub()}>Cancel Membership</button>
                            ) : (
                                (plan.id === 1) ? (
                                    <button onClick={() => Checkout('token', plan.priceId, plan.name)}>Buy Credit</button>
                                ) : (
                                    <button onClick={() => ChangeSub(plan.priceId, plan.name)}>Change Membership</button>
                                    // <button onClick={() => Checkout('sub', plan.priceId, plan.name)}>Change Membership</button>
                                )
                            )
                        )}
                    </div>
                </div>
            ))}
        </div>
    );

    const Message = ({ message }) => (
        <section>
        <p>{message}</p>
        </section>
    );

    return (
        <ProductsDisplay />
    );
};

export default PricingPage;
