import React, { useState, useEffect } from 'react';
import './footer.css';
import ReportProblemPopover from './ReportProblemPopover';

import { useStory } from '../context/StoryContext';

const Footer = () => {
  const [showPopover, setShowPopover] = useState(false);

  const {
    loggedIn
  } = useStory();

  useEffect(() => {
    const footer = document.querySelector('.App-footer');
    if (loggedIn) {
      footer.classList.add('logged-in');
    } else {
      footer.classList.remove('logged-in');
    }
  }, [loggedIn]);

  return (
    <>
      {loggedIn && showPopover && (
          <ReportProblemPopover onClose={() => setShowPopover(false)} />
      )}
      <div className="App-footer">
        <div className="footer-text">
          KidsTales AI © 2024<br />
          All content is provided using generative AI. Hallucinations and errors will occur, we are actively working to minimize these errors.
        </div>
        {loggedIn && (
        <>
          <button onClick={() => setShowPopover(true)} className="neu-button">
            Report a Problem
          </button>
        </>
      )}
      </div>
    </>
  );
};

export default Footer;
