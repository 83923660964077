// src/SkeletonLoader.js
import React from 'react';
import './skeleton-loader.css'; // Make sure to create this CSS file
// import '../story/book/BoxLoader.css';

const SkeletonLoader = ({ width, height, content }) => {
    
  return (
    <div 
        className="skeleton-loader" 
        style={{ width, height }}
    >
         {/* <div className="loading-animation">
            <div className="book-loader">
                <div></div>
            </div>
        </div> */}
        <div
            className="loader-content" 
            dangerouslySetInnerHTML={{ __html: content }}
        ></div>
    </div>
  );
};

export default SkeletonLoader;