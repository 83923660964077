import React, { createContext, useState, useContext } from 'react';

const ShelfContext = createContext();

export const useShelf = () => useContext(ShelfContext);

export const ShelfProvider = ({ children }) => {
    const maxBooks = 50;

    const [books, setBooks] = useState([]);
    const [booksVisible, setBooksVisible] = useState([]);
    const [index, setIndex] = useState(1);
    const [landingShelfOn, setLandingShelfOn] = useState(true);
    const [startBookIndex, setStartBookIndex] = useState(0);
    const [numVisible, setNumVisible] = useState(maxBooks);
    const [backgroundUrl, setBackgroundUrl] = useState(null);

    return (
        <ShelfContext.Provider 
            value={{ 
                books,
                setBooks,
                booksVisible,
                setBooksVisible,
                index,
                setIndex,
                landingShelfOn,
                setLandingShelfOn,
                startBookIndex,
                setStartBookIndex,
                numVisible,
                setNumVisible,
                maxBooks,
                backgroundUrl,
                setBackgroundUrl
            }}>
            {children}
        </ShelfContext.Provider>
    );
};
