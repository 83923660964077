// PinInputPopup.js
import React, { useState, useRef } from 'react';
import './pin-module.css'; // Make sure to create and style this

const PinModule = ({ isOpen, onClose, onPinSubmit }) => {
  const [pin, setPin] = useState(['', '', '', '']);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRefs = useRef([]);

  const handleInputChange = (event, index) => {
    const newPin = [...pin];
    newPin[index] = event.target.value.slice(0, 1);
    setPin(newPin);

    // Automatically move to next input field
    if (event.target.value && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !pin[index] && index > 0) {
      // Move to previous input field on backspace if current field is empty
      const newPin = [...pin];
      newPin[index - 1] = '';
      setPin(newPin);
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const pinCode = pin.join('');
    if (pinCode.length < 4) {
      setErrorMessage('Please fill in all PIN fields.');
      return;
    }
    onPinSubmit(pinCode, setErrorMessage);
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup">
        <h2>Enter Generating PIN</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="pin-inputs">
            {pin.map((value, index) => (
              <input
                key={index}
                type="password"
                maxLength="1"
                value={value}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                className="pin-input"
                autoFocus={index === 0}
                ref={(el) => (inputRefs.current[index] = el)}
              />
            ))}
          </div>
          <button className="neu-button fit" onClick={onClose} type="button">Cancel</button>
          <button className="neu-button fit submit" type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PinModule;
