import React, { useState } from 'react';
import API from '../utils/api_axios';
import { useStory } from '../context/StoryContext';
import './ReportProblemPopover.css';

const ReportProblemPopover = ({ onClose }) => {
  const { userId } = useStory();
  const [description, setDescription] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.post('/api/error/report', { 
        userId,
        description,
        pageUrl: window.location.href,
      });
      alert('Problem reported successfully!');
      onClose();
    } catch (error) {
      console.error('Error reporting problem:', error);
      alert('Failed to report problem. Please try again.');
    }
  };

  return (
    <div className="report-problem-popover">
      <h2 className="report-problem-popover-title">Report a Problem</h2>
      <form onSubmit={handleSubmit}>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Describe the problem..."
          required
        />
        <br />
        <div className="button-group">
          <button className="neu-button" type="submit">Submit</button>
          <br />
          <button className="neu-button" type="button" onClick={onClose}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default ReportProblemPopover;