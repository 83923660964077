import axios from 'axios';

import { __DEV__ } from '../DEV';
// const __DEV__ = true;

const API_PROD_URL = 'https://kidstales-api-production.up.railway.app';
const API_DEV_URL = 'http://localhost:3013';

const UI_PROD_URL = 'https://kidstales.studio';
// // const DEV_UI_URL = 'http://localhost:3001';
const UI_DEV_URL = 'http://localhost:3000';


// const API_PROD_URL = process.env.NODE_ENV === "production" ? process.env.API_PROD_URL : require('../config').apiProdUrl;
// const API_DEV_URL = process.env.NODE_ENV === "production" ? process.env.API_DEV_URL : require('../config').apiDeviUrl;

// const UI_PROD_URL = process.env.NODE_ENV === "production" ? process.env.UI_PROD_URL : require('../config').uiProdUrl;
// const UI_DEV_URL = process.env.NODE_ENV === "production" ? process.env.UI_DEV_URL : require('../config').uiDevUrl;

console.log('API_PROD_URL:', API_PROD_URL);
console.log('API_DEV_URL:', API_DEV_URL);
console.log('UI_PROD_URL:', UI_PROD_URL);
console.log('UI_DEV_URL:', UI_DEV_URL);

// const API_PROD_URL = process.env.API_PROD_URL;
// const API_DEV_URL = process.env.API_DEV_URL;

// const UI_PROD_URL = process.env.UI_PROD_URL;
// const UI_DEV_URL = process.env.UI_DEV_URL;

var KT_API_URL = __DEV__ ? API_DEV_URL : API_PROD_URL;
var KT_UI_URL = __DEV__ ? UI_DEV_URL : UI_PROD_URL;

console.log('KT_API_URL:', KT_API_URL);
console.log('KT_UI_URL:', KT_UI_URL);



const API = axios.create({
    // baseURL: `${window.location.protocol}//${window.location.hostname}:3013`,
    // baseURL: `http://127.0.0.1:3013`,
    // baseURL: `https://kidstales-8ac7be95d5e5.herokuapp.com:3013`,
    // baseURL: `https://kidstales-api-production.up.railway.app`,
    baseURL: KT_API_URL,
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": [KT_UI_URL, UI_PROD_URL, UI_DEV_URL],
      // "Access-Control-Allow-Origin": KT_UI_URL,
      // "Access-Control-Allow-Origin": KT_DEV_URL,
      "Access-Control-Allow-Credentials": "true",
      'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,HEAD',
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
    },
  });

export { KT_API_URL, KT_UI_URL, API };

export default API;